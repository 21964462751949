import styles from './exchange-sign-in.module.scss';
import Input from '../../../input/Input';
import MainButton from '../../../button/MainButton';
import {ReactComponent as BoltGreen} from '../../../../assets/svg/bolt-green.svg';
import {ReactComponent as ShieldWithKey} from '../../../../assets/svg/shield-with-key.svg';

const ExchangeSignIn = ({ provider, handleNextStep, authFields, canProceed = true }) => {
	return <div className={styles.container}>
		<div></div>{/* Top marker for even spacing, do not remove */}

		<div className={styles.mainContent}>
			<div className={styles.heading}>
				<div className={styles.title}>
					Authorize
				</div>
				<div className={styles.explanation}>
					Enter your {provider?.description} API key credentials
				</div>
			</div>
			{authFields.map(authField => <div className={styles.formInput} key={authField.key}>
				<div className={styles.label}>
					{authField.label}
				</div>
				<Input
					value={authField.value}
					onChange={e => authField.setValue(e.target.value)}
					type={authField.isPasswordType ? 'password' : 'text'}
					placeholder={authField.label}
				/>
			</div>)}
			<MainButton
				className='confirm-button'
				onClick={handleNextStep}
				label='Continue'
				icon={<BoltGreen className='bolt-icon'/>}
				disabled={!canProceed}
			/>
		</div>

		<div className={styles.footer}>
			<div className={styles.iconContainer}>
				<ShieldWithKey />
			</div>
			<div className={styles.text}>
				Your data is private and encrypted at all times
			</div>
		</div>
	</div>;
};

export default ExchangeSignIn;
