import styles from './deposit-intro.module.scss';
import ModalFooter from '../../../modal-components/modal-footer/ModalFooter';
import MainButton from '../../../button/MainButton';
import {ReactComponent as BoltGreen} from '../../../../assets/svg/bolt-green.svg';
import {ReactComponent as BlockmateLogo} from '../../../../assets/svg/blockmate-logo.svg';
import {ReactComponent as UserIcon} from '../../../../assets/svg/user.svg';
import {ReactComponent as ShieldIcon} from '../../../../assets/svg/shield.svg';
import {ReactComponent as LockIcon} from '../../../../assets/svg/lock.svg';
import {ReactComponent as ArrowsIcon} from '../../../../assets/svg/arrows.svg';
import LinkedLogos from '../common/LinkedLogos';

const DepositIntro = ({ isLoading = false, handleNextStep, targetInfo, canProceed = true }) => {
	const infoRows = [
		{
			icon: <UserIcon />,
			text: 'Link never stores your personal information'
		},
		{
			icon: <ShieldIcon />,
			text: 'Link always encrypts your data'
		},
		{
			icon: <LockIcon />,
			text: 'Link never touches your assets'
		},
		{
			icon: <ArrowsIcon />,
			text: 'Link cannot initiate transfers without your explicit approval'
		}
	];

	return <div className={styles.container}>
		<div className={styles.topContainer}>
			<div className={styles.headingContainer}>
				<div>
					<LinkedLogos
						leftLogo={<img src={targetInfo.icon} alt={targetInfo.description} />}
						rightLogo={<BlockmateLogo />}
					/>
				</div>
				<div className={styles.titleContainer}>
					<div className={styles.boldText}>
						{targetInfo.description} uses Link
					</div>
					<div className={styles.regularText}>
						to securely connect your accounts
					</div>
				</div>
			</div>
			<div className={styles.contentContainer}>
				{infoRows.map(row => <div className={styles.infoRowContainer} key={row.text}>
					<div className={styles.icon}>
						{row.icon}
					</div>
					<div className={styles.text}>
						{row.text}
					</div>
				</div>)}
			</div>
		</div>
		<div className={styles.bottomContainer}>
			{isLoading
				? <MainButton
					className='confirm-button'
					label='Loading'
					icon={<BoltGreen className='bolt-icon'/>}
					disabled={true}
				/>
				: <MainButton
					className='confirm-button'
					onClick={handleNextStep}
					label='Continue'
					icon={<BoltGreen className='bolt-icon'/>}
					disabled={!canProceed}
				/>
			}
			<ModalFooter />
		</div>
	</div>;
};

export default DepositIntro;
