import useApiInstances from './axios';
import {valueExists} from '../../utils/utils';

const useDeposit = (jwt) => {
	const {apiBlockmate} = useApiInstances();
	const headers = {
		Authorization: `Bearer ${jwt}`
	};

	const initialize = async (fiatAmount, fiatCurrency, exchangeName) => {
		const body = {
			fiat_amount: fiatAmount,
			fiat_currency: fiatCurrency,
			name: exchangeName,
		};
		const res = await apiBlockmate.post(
			'/v1/exchange/deposit/initialize',
			body,
			{headers}
		);
		return res?.data?.deposit_id;
	};

	const authorize = async (depositId, name, merchantUrl, apiKey, apiSecret, apiKeyPassphrase) => {
		const body = {
			merchant_url: merchantUrl,
		};
		if (apiKey) {
			body.api_key = apiKey;
		}
		if (apiSecret) {
			body.api_secret = apiSecret;
		}
		if (apiKeyPassphrase) {
			body.api_key_passphrase = apiKeyPassphrase;
		}
		if (valueExists(name)) {
			body.name = name;
		}
		const res = await apiBlockmate.post(
			`/v1/exchange/deposit/${depositId}/authorize`,
			body,
			{headers}
		);
		return res?.data?.redirect_url;
	};

	const simpleOverview = async (depositId) => {
		const res = await apiBlockmate.get(
			`/v1/exchange/deposit/${depositId}/simple-overview`,
			{headers}
		);
		return res?.data;
	};

	const overview = async (depositId) => {
		const res = await apiBlockmate.get(
			`/v1/exchange/deposit/${depositId}/overview`,
			{headers}
		);
		return res?.data;
	};

	const finish = async (depositId, exchangeAccountId, network, secondFactorToken = undefined) => {
		const res = await apiBlockmate.post(
			`/v1/exchange/deposit/${depositId}/finish`,
			{
				exchange_account_id: exchangeAccountId,
				network: network,
				second_factor_token: secondFactorToken,
			},
			{headers}
		);
		return res?.data?.redirect_url;
	};

	const back = async (depositId) => {
		return await apiBlockmate.post(
			`/v1/exchange/deposit/${depositId}/back`,
			{},
			{headers}
		);
	};

	const check = async (depositId) => {
		const res = await apiBlockmate.get(
			`/v1/exchange/deposit/${depositId}/check`,
			{headers}
		);
		return res?.data;
	};

	return {
		initialize,
		authorize,
		simpleOverview,
		overview,
		finish,
		back,
		check,
	};
};

export default useDeposit;