import styles from './payments.module.scss';
import { ReactComponent as BlockmateLogo } from '../../assets/svg/blockmate-logo-general.svg';
import { ReactComponent as BoltGreen } from '../../assets/svg/bolt-green.svg';
import MainButton from '../../components/button/MainButton';
import { useSearchParams } from 'react-router-dom';
import {handleOpen, LinkModal} from 'blockmate-react-link';
import useUniversalDeposit from '../../services/api-call/universalDeposit';
import {useState, useMemo, useEffect} from 'react';
import PersistentAlert, {ALERT_TYPES} from '../../components/persistent-alert/PersistentAlert';
import SelectNative from '../../components/select/SelectNative';

const DEPOSIT_TYPE_OPTIONS = [
	{
		depositType: 'WalletConnect',
		exchangeName: null,
	},
	{
		depositType: 'Coinbase',
		exchangeName: 'coinbase',
	},
	{
		depositType: 'Binance',
		exchangeName: 'binance',
	},
];

const Payments = () => {
	const nonDeletableQueryParamKeys = [
		'jwt',
		'depositId',
		'merchantDescription',
		'merchantIcon',
		'successUrl',
		'orderId',
	];

	const [searchParams] = useSearchParams();

	const [selectedDepositTypeOption, setSelectedDepositTypeOption] = useState(DEPOSIT_TYPE_OPTIONS[0]);
	const [canChangeDepositType, setCanChangeDepositType] = useState(false);

	const [paymentInProgress, setPaymentInProgress] = useState(() => {
		const deletableParams = [];
		searchParams.forEach((_, key) => {
			if (!nonDeletableQueryParamKeys.includes(key)) {
				deletableParams.push(key);
			}
		});
		const deletableThrowawayParams = ['depositType'];
		return deletableParams.filter(param => !deletableThrowawayParams.includes(param)).length > 0;
	});

	const [isLoading, setIsLoading] = useState(true);

	const [errors, setErrors] = useState([]);

	const nonDeletableQueryParams = useMemo(() => {
		const params = {};
		for (const key of nonDeletableQueryParamKeys) {
			const value = searchParams.get(key);
			if (value) {
				params[key] = value;
			}
		}
		return params;
	}, [searchParams]);

	const linkUrl = window.location.origin;

	const { restart, changeType, simpleOverview } = useUniversalDeposit(nonDeletableQueryParams.jwt);

	useEffect(() => {
		const getInitialDepositType = async () => {
			setIsLoading(true);
			const depositInfo = await simpleOverview(nonDeletableQueryParams.depositId);
			const initialOption = DEPOSIT_TYPE_OPTIONS.find(option => option?.exchangeName === depositInfo?.name);
			setSelectedDepositTypeOption(initialOption);
			setCanChangeDepositType(depositInfo.state_detail === 'initializing');
			setIsLoading(false);
		};
		getInitialDepositType().catch(console.error);
	}, []);

	const handleDepositTypeChange = async (option) => {
		setIsLoading(true);
		try {
			await changeType(nonDeletableQueryParams.depositId, option?.exchangeName);
			setSelectedDepositTypeOption(option);
		} catch (err) {
			setErrors(prevErrors => ([...prevErrors, `Error setting the new deposit type: ${err?.response?.data?.detail ?? ''}`]));
		}
		setIsLoading(false);
	};

	const handleProcessPayment = async () => {
		setIsLoading(true);
		setPaymentInProgress(true);
		setErrors([]);
		let depositInfo = {};
		try {
			depositInfo = await simpleOverview(nonDeletableQueryParams.depositId);
		} catch(err) {
			setErrors(prevErrors => [...prevErrors, 'Problem getting deposit info. Please start a new process through the merchant.']);
			setPaymentInProgress(false);
			setIsLoading(false);
			return;
		}

		if (depositInfo?.state_detail && depositInfo?.state_detail !== 'initializing') {
			setErrors(prevErrors => [
				...prevErrors,
				`The state of this deposit is ${String(depositInfo.state_detail).toUpperCase()} and cannot be manipulated anymore.`]
			);
			setIsLoading(false);
			return;
		}

		if (selectedDepositTypeOption.exchangeName) {
			handleOpen(
				'deposit',
				undefined,
				undefined,
				{
					providerName: depositInfo.name,
					depositId: nonDeletableQueryParams.depositId,
					fiatAmount: String(depositInfo.fiat_amount),
					fiatCurrency: depositInfo.fiat_currency,
					jwt: nonDeletableQueryParams.jwt,
					merchantDescription: nonDeletableQueryParams.merchantDescription,
					merchantIcon: nonDeletableQueryParams.merchantIcon,
				}
			);
		} else {
			handleOpen(
				'directDeposit',
				undefined,
				undefined,
				{
					depositId: nonDeletableQueryParams.depositId,
					jwt: nonDeletableQueryParams.jwt,
					merchantDescription: nonDeletableQueryParams.merchantDescription,
					merchantIcon: nonDeletableQueryParams.merchantIcon,
				}
			);
		}
		setIsLoading(false);
	};

	const restartProcess = async () => {
		setIsLoading(true);
		try {
			await restart(nonDeletableQueryParams.depositId);
		} catch (err) {
			console.error(`Failed to fully restart the deposit: ${err.toString()}`);
		}

		const currentUrl = new URL(window.location.href);
		const newParams = new URLSearchParams();
		for (const [key, value] of Object.entries(nonDeletableQueryParams)) {
			newParams.set(key, value);
		}
		setIsLoading(false);
		window.location.href = `${currentUrl.origin}${currentUrl.pathname}?${newParams.toString()}`;
	};

	const returnToMerchant = () => {
		window.location.href = nonDeletableQueryParams.successUrl;
	};

	return <div className={styles.container}>
		<div className={styles.allAlertsContainer}>
			{(errors ?? []).length > 0 && <div className={styles.errorsContainer}>
				{errors.map((error, index) => <div className={styles.error} key={`error-${index}`}>
					<PersistentAlert
						type={ALERT_TYPES.error}
						message={error}
						handleClose={() => {
							setErrors(prevErrors => {
								const newErrors = [...prevErrors];
								newErrors.splice(index, 1);
								return newErrors;
							});
						}}
					/>
				</div>)}
			</div>}
		</div>
		<div className={styles.contentContainer}>
			<LinkModal
				url={linkUrl}
				jwt={nonDeletableQueryParams.jwt}
				additionalUrlParams={{
					merchantDescription: nonDeletableQueryParams.merchantDescription,
					merchantIcon: nonDeletableQueryParams.merchantIcon,
				}}
				cleanupActions={{
					depositSuccess: returnToMerchant,
				}}
			/>

			<div className={styles.logoContainer}>
				<BlockmateLogo/>
			</div>
			<div className={styles.title}>Complete the cryptocurrency payment of your order</div>

			<div className={styles.explanation}>
				<span className={styles.alertText}>Do not close</span> this site until you are redirected back to the merchant.
			</div>

			<div className={styles.payNowFormContainer}>
				{canChangeDepositType && <div>
					<SelectNative
						options={DEPOSIT_TYPE_OPTIONS}
						keySelector='depositType'
						onChange={handleDepositTypeChange}
						value={selectedDepositTypeOption.depositType}
						extraClasses={styles.depositTypeSelect}
					/>
				</div>}

				<MainButton
					className='confirm-button'
					onClick={handleProcessPayment}
					icon={<BoltGreen className='bolt-icon'/>}
					disabled={isLoading || paymentInProgress}
					label='Pay now'
				/>
			</div>

			{paymentInProgress && <div className={styles.restartProcess} onClick={restartProcess}>
				&#x21bb; Restart process
			</div>}
		</div>
		<div></div> {/* Empty div so that space-between puts main div to the middle */}
	</div>;
};

export default Payments;
