import styles from './select-native.module.scss';
import { clsx } from 'clsx';

const SelectNative = ({
	options,
	value,
	onChange,
	keySelector,
	extraClasses
}) => {

	const handleChange = (e) => {
		const newlySelectedOption = options.find(option => option[keySelector] === e.target.value);
		onChange(newlySelectedOption);
	};

	return <select value={value} onChange={handleChange} className={clsx(styles.container, extraClasses)}>
		{options.map(option => <option key={option[keySelector]} value={option[keySelector]}>
			{option[keySelector]}
		</option>)}
	</select>;
};

export default SelectNative;
