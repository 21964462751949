import useApiInstances from './axios';

const useUniversalDeposit = (jwt) => {
	const {apiBlockmate} = useApiInstances();
	const headers = {
		Authorization: `Bearer ${jwt}`
	};

	const simpleOverview = async (depositId) => {
		const res = await apiBlockmate.get(
			`/v1/exchange/deposit/universal/${depositId}/simple-overview`,
			{headers}
		);
		return res?.data;
	};

	const check = async (depositId) => {
		const res = await apiBlockmate.get(
			`/v1/exchange/deposit/universal/${depositId}/check`,
			{headers},
		);
		return res?.data;
	};

	const restart = async (depositId) => {
		await apiBlockmate.get(
			`/v1/exchange/deposit/universal/${depositId}/restart`,
			{headers},
		);
	};

	const changeType = async (depositId, exchangeName) => {
		await apiBlockmate.post(
			`/v1/exchange/deposit/universal/${depositId}/change-type`,
			{
				deposit_id: depositId,
				exchange_name: exchangeName,
			},
			{headers},
		);
	};

	return {
		simpleOverview,
		check,
		restart,
		changeType,
	};
};

export default useUniversalDeposit;
